<template>
  <light-layout :active="3">
    <section class="section-padding border-bottom">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-6 col-xl-5">
            <div class="mb-5 mb-md-0">
              <img
                class="img-fluid"
                src="../../assets/images/app2.png"
                loading="lazy"
                alt="…"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-6 offset-xl-1">
            <div class="mb-3">
              <h3 class=" fs-3 fw-bold mb-2">Mobile app</h3>
              <p>
                Get the best mobile app to exchange or buy crypto on the go:
              </p>
            </div>
            <ul class="check-list">
              <li>Best rates on the market</li>
              <li>High limits</li>
              <li>No verification for exchange transactions</li>
              <li>More than 150 cryptocurrencies</li>
              <li>Buy bitcoin | buy crypto with USD, EUR or GBP</li>
            </ul>
            <div class="mt-4 d-grid gap-2 d-sm-block">
              <a href="#!" class="btn btn-lg btn-primary me-sm-3 rounded-pill">
                <img
                  src="../../assets/images/android.svg"
                  loading="lazy"
                  alt="…"
                />
              </a>
              <a href="#!" class="btn btn-lg btn-primary rounded-pill">
                <img
                  src="../../assets/images/apple.svg"
                  loading="lazy"
                  alt="…"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="wallet-feature section-padding">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-xl-8">
            <div class="section-heading text-center">
              <h2>Tradix for wallets</h2>
            </div>
          </div>
        </div>
        <!-- section tile end -->
        <div class="row g-4">
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="text-center">
              <span class="text-primary mb-2 fs-1"><i class="la la-user-plus"></i></span>
              <h5 class="fw-semi-bold mx-auto">Built-in white-label instant exchange</h5>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="text-center">
              <span class="text-primary mb-2 fs-1"><i class="la la-bank"></i></span>
              <h5 class="fw-semi-bold mx-auto">Dedicated support line</h5>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="text-center">
              <span class="text-primary mb-2 fs-1"><i class="la la-exchange"></i></span>
              <h5 class="fw-semi-bold mx-auto">Top-up the wallet with 140+ cryptos</h5>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="text-center">
              <span class="text-primary mb-2 fs-1"><i class="la la-exchange"></i></span>
              <h5 class="fw-semi-bold mx-auto">Vast cross-marketing opportunities</h5>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="text-center">
              <span class="text-primary mb-2 fs-1"><i class="la la-exchange"></i></span>
              <h5 class="fw-semi-bold mx-auto">Revenue share from every transaction</h5>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="text-center">
              <span class="text-primary mb-2 fs-1"><i class="la la-exchange"></i></span>
              <h5 class="fw-semi-bold mx-auto">Revenue share from every transaction</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="all-coin section-padding pt-0 border-bottom">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-xl-8">
            <div class="section-heading text-center">
              <h2>All your digital assets in one place</h2>
              <p>Take full control of your tokens and collectibles by storing them on your own device.</p>
            </div>
          </div>
        </div>
        <!-- section-heading end -->
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center flex-wrap">
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ADA" title="ADA"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ADA-alt" title="ADA"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ADC" title="ADC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ADC-alt" title="ADC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc AEON" title="AEON"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc AEON-alt" title="AEON"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc AMP" title="AMP"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc AMP-alt" title="AMP"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ANC" title="ANC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ANC-alt" title="ANC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ARCH" title="ARCH"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ARCH-alt" title="ARCH"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ARDR" title="ARDR"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ARDR-alt" title="ARDR"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ARK" title="ARK"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc ARK-alt" title="ARK"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc AUR" title="AUR"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc AUR-alt" title="AUR"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BANX" title="BANX"></i>
              </span>
              <span> <i class="cc BANX-alt" title="BANX"></i></span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BAT" title="BAT"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BAT-alt" title="BAT"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BAY" title="BAY"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BAY-alt" title="BAY"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BC" title="BC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BC-alt" title="BC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BCN" title="BCN"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BCN-alt" title="BCN"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BFT" title="BFT"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BFT-alt" title="BFT"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BRK" title="BRK"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BRK-alt" title="BRK"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BRX" title="BRX"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BRX-alt" title="BRX"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BSD" title="BSD"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BSD-alt" title="BSD"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTA" title="BTA"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTC" title="BTC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTC-alt" title="BTC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BCH" title="BCC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BCH-alt" title="BCC"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTCD" title="BTCD"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTCD-alt" title="BTCD"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTM" title="BTM"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTM-alt" title="BTM"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTS" title="BTS"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BTS-alt" title="BTS"></i>
              </span>
              <span class="fs-1 border rounded-circle m-3 mt-0 d-flex align-items-center justify-content-center text-center">
                <i class="cc BURST" title="BURST"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="pt-5 mt-3 text-center">
              <h3 class="fs-4 fw-semi-bold mb-3">Multi-Coin Support</h3>
              <p class="text-gray"> Manage BTC, BCH, ETH, ETC, LTC, and all your ERC-20 tokens.</p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="pt-5 mt-3 text-center">
              <h3 class="fs-4 fw-semi-bold mb-3">Digital collectibles</h3>
              <p class="text-gray"> Cats, monsters, art - store all your ERC721 collectibles in a single beautiful gallery.</p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="pt-5 mt-3 text-center">
              <h3 class="fs-4 fw-semi-bold mb-3">Secure storage</h3>
              <p class="text-gray"> Your keys are protected with Secure Enclave and biometric authentication technology.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </light-layout>
</template>

<script>
import LightLayout from "../../components/Landing/Light/LightLayout.vue";
export default {
  components: { LightLayout },
};
</script>

