<template>
  <dark-layout :active="4">
    <section class="blog section-padding">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-8 mb-5 mb-lg-0">
            <article class="posts">
              <ul class="d-sm-flex mb-3">
                <li class="me-4 text-gray d-flex align-items-center">
                  <i class="la la-calendar me-1"></i>April 25, 2018
                </li>
                <li class="me-4 text-gray d-flex align-items-center">
                  <i class="la la-comment-o me-1"></i
                  ><a href="#!" class="text-gray fs-6">4 Comments</a>
                </li>
                <li class="me-4 text-gray d-flex align-items-center">
                  <i class="la la-bookmark-o me-1"></i
                  ><a href="#!" class="text-gray fs-6">Apartments</a>
                </li>
              </ul>
              <h4 class="fw-medium fs-3 mb-4 text-dark-300">
                Real Estate near ocean
              </h4>
              <div class="mb-5">
                <img
                  src="../../assets/images/blog/1.jpg"
                  loading="lazy"
                  alt="…"
                  class="img-fluid"
                />
              </div>
              <div class="post-blog">
                <p class="lh-lg text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  volutpat nec purus eget porta. Aliquam ebendum erat. Donec dui
                  eros, tincidunt at felis non, tristique aliquet ex. Aenean
                  luctus, orci condimentum cursus, quam lorem vulputate ligula,
                  ac pretium risus metus non est. Cras rutrum dolor in tortor
                  ultrices, ullamcorper finibus magna sollicitudin. Vivamus sed
                  massa sit amet diam porta dignissim at in lorem. In facilisis
                  quis erat at tempus.
                </p>

                <p class="lh-lg text-white">
                  Aliquam semper diam mollis mollis. Mauris dictum, ante ac
                  interdum.Astibulum, nibh ipsum condimentum felis, quis luctus
                  nisi nisl sed orci. Pellentesque habitant morbi tristique
                  senectus et netus et malesuada fames ac turpis egestas. Sed
                  tempus puet rutrum ultrces. Cras pretium pretium odio aliquam
                  tortor interduma. Morbi commodo egestas mauris, et porttitor
                  ipsum iaculis fermentum. Phasellus ante nibh, posuere gravida
                  odio mattis cursus.
                </p>

                <blockquote
                  class="text-dark-400 fs-5 fw-semi-bold px-3 my-4 border-color-default"
                >
                  Donec sapien odio, mollis ut phaliquet hendrerit erat. Etiam
                  mollis odio ac libero ultrices cursus. Mauris massa felis,
                  rutrum vitae velit et. Aliquam ac neque in dui eleifend
                  elementum vitae mi.
                </blockquote>

                <p class="lh-lg text-white">
                  Praesent bibendum eget justo ac volutpat. Proin laoreet
                  hendrerit porttitor. Praesent ac lobortis urna. Nam vi ligula
                  nec posuere ornare. Integer aliquet libero at lectus
                  scelerisque fermentum. Sed dapibus massa ut ex semper
                  porttitor. Donec blandit dui sit amet nunc sagittis, ut
                  convallis ligula tempor. Vestibulum at tincidunt mi. Proin
                  venenatis dui et ex lobortis ultricies.
                </p>

                <div class="d-md-flex align-items-center mb-4 mt-1">
                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <img
                      src="../../assets/images/blog/2.jpg"
                      loading="lazy"
                      alt="…"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <p class="lh-lg text-white ms-md-5">
                      Orci varius natoque penatibus et magnis disa parturient
                      montes, nascetur ridiculus mus. Vestibulum scelerisque
                      commodo ultricies. Phasellus vite ipsum eget diam feme
                      ntum tempor quis nec diam. Nulla at lacus consequat.
                    </p>
                  </div>
                </div>

                <p class="lh-lg text-white">
                  Ut egestas fringilla commodo. Phasellus ac mi vel massa mattis
                  elementum non et quam. Pellentesque habitant morbi tristique
                  senectus et netus et malesuada fames ac turpis egestas.
                  Praesent at nibh eros. Curabitur rutrum fermentum augue, ut
                  auctor elit tempor scelerisque. Pellentesque habitant morbi
                  tristique senectus et netus et malesuada fames ac turpis
                  egestas. Vivamus sed ante eu justo feugiat fringilla sit amet
                  quis arcu. Vivamus eget cursus ligula, condimentum feugiat
                  velit, a viverra urna placerat et.
                </p>

                <ul class="list-article ps-3 mt-2 mb-4">
                  <li class="text-dark-400 mb-3 position-relative ps-4">
                    Nunc varius varius dolor, sit amet dignissim ligula placerat
                    ullamcorper quam a magna tempus ornare.
                  </li>
                  <li class="text-dark-400 mb-3 position-relative ps-4">
                    Aliquam sapien lorem, aliquet consequat neque vel, placerat
                    euismod isl vitae velit elementum aliquet.
                  </li>
                  <li class="text-dark-400 mb-3 position-relative ps-4">
                    Sed id orci laoreet, lacinia ligula eget, fringilla metus.
                    Quisque nec or condimentum accumsan neque.
                  </li>
                </ul>

                <p class="lh-lg text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  volutpat nec purus eget porta. Aliquam ebendum erat. Donec dui
                  eros, tincidunt at felis non, tristique aliquet ex. Aenean
                  luctus, orci condimentum cursus, quam lorem vulputate ligula,
                  ac pretium risus metus non est. Cras rutrum dolor in tortor
                  ultrices, ullamcorper finibus magna sollicitudin. Vivamus sed
                  massa sit amet diam porta dignissim at in lorem. In facilisis
                  quis erat at tempus. Aliquam semper diam mollis mollis. Mauris
                  dictum, ante ac interdum.
                </p>

                <p class="lh-lg text-white">
                  Astibulum, nibh ipsum condimentum felis, quis luctus nisi nisl
                  sed orci. Pellentesque habitant morbi tristique senectus et
                  netus et malesuada fames ac turpis egestas. Sed tempus puet
                  rutrum ultrces. Cras pretium pretium odio aliquam tortor
                  interduma. Morbi commodo egestas mauris, et porttitor ipsum
                  iaculis fermentum. Phasellus ante nibh, posuere gravida odio
                  cursus risus.
                </p>
                <div
                  class="d-flex justify-content-between flex-wrap align-items-center border-top border-color-default pt-3 pb-5"
                >
                  <ul class="list-inline">
                    <li class="list-inline-item me-3">
                      <a href="#!" class="fs-6"
                        ><i class="fab fa-facebook-f text-primary"></i
                      ></a>
                    </li>
                    <li class="list-inline-item me-3">
                      <a href="#!" class="fs-6"
                        ><i class="fab fa-twitter text-primary"></i
                      ></a>
                    </li>
                    <li class="list-inline-item me-3">
                      <a href="#!" class="fs-6"
                        ><i class="fab fa-instagram text-primary"></i
                      ></a>
                    </li>
                    <li class="list-inline-item me-3">
                      <a href="#!" class="fs-6"
                        ><i class="fab fa-linkedin text-primary"></i
                      ></a>
                    </li>
                  </ul>
                  <a href="#comment-section" class="text-dark-400 fs-6"
                    ><span class="me-2">Write A Comment </span
                    ><i class="la la-arrow-right"></i
                  ></a>
                </div>

                <div class="media d-flex align-items-start border p-4 mb-5">
                  <img
                    src="../../assets/images/blog/cm-img.png"
                    loading="lazy"
                    alt="…"
                  />
                  <div class="media-body ms-4">
                    <h4 class="text-dark-400 fw-semi-bold fs-5 mb-2">
                      Endrey Tomson
                    </h4>
                    <p class="lh-lg text-white mb-3">
                      Etiam euismod iaculis urna vel venenatis. Morbi rutrum
                      commodo enim. Vivamus tinci dunt leo vel arcu elnd
                      euismodtis purus in, pulvinar tellus nisl aliquam pretium
                      ac.
                    </p>
                    <ul class="list-inline social-links">
                      <li class="list-inline-item me-3">
                        <a href="#!" class="fs-6"
                          ><i class="fab fa-facebook-f text-primary"></i
                        ></a>
                      </li>
                      <li class="list-inline-item me-3">
                        <a href="#!" class="fs-6"
                          ><i class="fab fa-twitter text-primary"></i
                        ></a>
                      </li>
                      <li class="list-inline-item me-3">
                        <a href="#!" class="fs-6"
                          ><i class="fab fa-instagram text-primary"></i
                        ></a>
                      </li>
                      <li class="list-inline-item me-3">
                        <a href="#!" class="fs-6"
                          ><i class="fab fa-linkedin text-primary"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </article>
            <!-- article end -->
            <div class="mb-5 pb-3">
              <h4
                class="fw-bold fs-5 fw-medium text-uppercase mb-4 text-dark-300"
              >
                Comments
              </h4>
              <ul class="list-unstyled">
                <li class="mb-5">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex mb-3">
                      <img
                        src="../../assets/images/blog/cm-img.png"
                        loading="lazy"
                        alt="…"
                      />
                      <div class="ms-4">
                        <h4 class="text-dark-400 fw-semi-bold fs-5 mb-2">
                          Kritsofer Nolan
                        </h4>
                        <h5 class="fw-semi-bold text-gray fs-6">
                          April 25, 2018
                        </h5>
                      </div>
                    </div>
                    <a href="#!" class="text-primary cm-reply align-self-start"
                      >Reply</a
                    >
                  </div>
                  <p class="lh-lg text-white">
                    Nam placerat facilisis placerat. Morbi elit nibh, auctor sit
                    amet sodales id, porttitor eu quam. Aenean dui libero,
                    laoreet quis con sequat vitae, posuere ut sapien. Etiam
                    pharetra nulla vel diam eleifend, eu placerat nunc molestie.
                  </p>
                </li>
                <li class="mb-5">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex mb-3">
                      <img
                        src="../../assets/images/blog/cm-img.png"
                        loading="lazy"
                        alt="…"
                      />
                      <div class="ms-4">
                        <h4 class="text-dark-400 fw-semi-bold fs-5 mb-2">
                          Kritsofer Nolan
                        </h4>
                        <h5 class="fw-semi-bold text-gray fs-6">
                          April 25, 2018
                        </h5>
                      </div>
                    </div>
                    <a href="#!" class="text-primary cm-reply align-self-start"
                      >Reply</a
                    >
                  </div>
                  <p class="lh-lg text-white">
                    Nam placerat facilisis placerat. Morbi elit nibh, auctor sit
                    amet sodales id, porttitor eu quam. Aenean dui libero,
                    laoreet quis con sequat vitae, posuere ut sapien. Etiam
                    pharetra nulla vel diam eleifend, eu placerat nunc molestie.
                  </p>
                </li>
                <li class="mb-5">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex mb-3">
                      <img
                        src="../../assets/images/blog/cm-img.png"
                        loading="lazy"
                        alt="…"
                      />
                      <div class="ms-4">
                        <h4 class="text-dark-400 fw-semi-bold fs-5 mb-2">
                          Kritsofer Nolan
                        </h4>
                        <h5 class="fw-semi-bold text-gray fs-6">
                          April 25, 2018
                        </h5>
                      </div>
                    </div>
                    <a href="#!" class="text-primary cm-reply align-self-start"
                      >Reply</a
                    >
                  </div>
                  <p class="lh-lg text-white">
                    Nam placerat facilisis placerat. Morbi elit nibh, auctor sit
                    amet sodales id, porttitor eu quam. Aenean dui libero,
                    laoreet quis con sequat vitae, posuere ut sapien. Etiam
                    pharetra nulla vel diam eleifend, eu placerat nunc molestie.
                  </p>
                </li>
              </ul>
            </div>
            <!-- comments-area end -->
            <div>
              <h4
                class="fw-bold fs-5 fw-medium text-uppercase mb-4 text-dark-300"
              >
                Leave a reply
              </h4>
              <comment-form />
            </div>
            <!-- comments-respond end -->
          </div>

          <div class="col-12 col-lg-4">
            <div class="blog-sidebar ms-lg-4">
              <div class="widget mb-5 position-relative">
                <form action="#" class="form search-form">
                  <input
                    type="text"
                    class="form-control bg-transparent"
                    placeholder="Subscribe Now"
                  />
                  <button type="button" class="bg-transparent border-0">
                    <span><i class="la la-search"></i></span>
                  </button>
                </form>
              </div>
              <!-- widget end -->
              <div class="widget mb-5">
                <h4
                  class="text-dark-400 widget-title border-bottom fw-semi-bold pb-2 mb-4"
                >
                  Recent Post
                </h4>
                <ul class="list-unstyled">
                  <li class="media mb-4 d-flex align-items-center">
                    <img
                      src="../../assets/images/blog/post_1.jpg"
                      class="me-3 rounded"
                      loading="lazy"
                      alt="…"
                    />
                    <div class="media-body">
                      <h5 class="fw-semi-bold mb-1">
                        <a href="#!" class="text-dark-400"
                          >List-based media object</a
                        >
                      </h5>
                      <div class="meta-info">
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-user"></i> Admin</a
                        >
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-calendar"></i> 31 July, 2019</a
                        >
                      </div>
                    </div>
                  </li>
                  <li class="media mb-4 d-flex align-items-center">
                    <img
                      src="../../assets/images/blog/post_2.jpg"
                      class="me-3 rounded"
                      loading="lazy"
                      alt="…"
                    />
                    <div class="media-body">
                      <h5 class="fw-semi-bold mb-1">
                        <a href="#!" class="text-dark-400"
                          >List-based media object</a
                        >
                      </h5>
                      <div class="meta-info">
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-user"></i> Admin</a
                        >
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-calendar"></i> 31 July, 2019</a
                        >
                      </div>
                    </div>
                  </li>
                  <li class="media mb-4 d-flex align-items-center">
                    <img
                      src="../../assets/images/blog/post_3.jpg"
                      class="me-3 rounded"
                      loading="lazy"
                      alt="…"
                    />
                    <div class="media-body">
                      <h5 class="fw-semi-bold mb-1">
                        <a href="#!" class="text-dark-400"
                          >List-based media object</a
                        >
                      </h5>
                      <div class="meta-info">
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-user"></i> Admin</a
                        >
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-calendar"></i> 31 July, 2019</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- widget end -->
              <div class="widget mb-5">
                <h4
                  class="text-dark-400 widget-title border-bottom fw-semi-bold pb-2 mb-4"
                >
                  Categories
                </h4>
                <ul class="list-group">
                  <li
                    class="list-group-item bg-transparent d-flex justify-content-between align-items-center border-0 ps-0"
                  >
                    <a href="#!" class="text-gray">Cras justo odio</a>
                    <span class="badge bg-primary">14</span>
                  </li>
                  <li
                    class="list-group-item bg-transparent d-flex justify-content-between align-items-center border-0 ps-0"
                  >
                    <a href="#!" class="text-gray">Dapibus ac facilisis in</a>
                    <span class="badge bg-primary">2</span>
                  </li>
                  <li
                    class="list-group-item bg-transparent d-flex justify-content-between align-items-center border-0 ps-0"
                  >
                    <a href="#!" class="text-gray">Morbi leo risus</a>
                    <span class="badge bg-primary">1</span>
                  </li>
                </ul>
              </div>
              <!-- widget end -->
              <div class="widget widget-tag mb-5">
                <h4
                  class="text-dark-400 widget-title border-bottom fw-semi-bold pb-2 mb-4"
                >
                  Tags
                </h4>
                <ul class="list-inline tag-group">
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Tradix</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Song</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Title</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Good</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Dashboard</a
                    >
                  </li>
                </ul>
              </div>
              <!-- widget end -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </dark-layout>
</template>

<script>
import CommentForm from "../../components/Landing/CommentForm.vue";
import DarkLayout from "../../components/Landing/Dark/DarkLayout.vue";
export default {
  components: { DarkLayout, CommentForm },
};
</script>
