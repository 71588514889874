<template>
  <div>
    <footer class="footer section-padding bg-dark-blue">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="mb-4 mb-lg-0">
              <img
                class="pb-3"
                src="../../assets/images/logo-white.png"
                loading="lazy"
                alt="…"
              />
              <p class="text-gray">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="col-4 col-sm-3 col-lg-2">
            <div class="footer-widget mb-4 mb-sm-0">
              <h5
                class="fw-semi-bold text-dark-400 widget-title text-white mb-3"
              >
                Company
              </h5>
              <ul class="list-unstyled widget-item">
                <li>
                  <router-link to="#" class="text-gray  mb-2"
                    >About</router-link
                  >
                </li>
                <li>
                  <router-link to="#" class="text-gray  mb-2"
                    >Career</router-link
                  >
                </li>
                <li>
                  <router-link to="#" class="text-gray  mb-2"
                    >Affiliate</router-link
                  >
                </li>
                <li>
                  <router-link to="#" class="text-gray  mb-2"
                    >Our Partner</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 col-sm-3 col-lg-2">
            <div class="footer-widget mb-4 mb-sm-0">
              <h5
                class="fw-semi-bold text-dark-400 widget-title text-white mb-3"
              >
                Support
              </h5>
              <ul class="list-unstyled widget-item">
                <li>
                  <router-link to="#" class="text-gray  mb-2"
                    >Ticket</router-link
                  >
                </li>
                <li>
                  <router-link to="#" class="text-gray  mb-2">FAQ</router-link>
                </li>
                <li>
                  <router-link to="#" class="text-gray  mb-2">Blog</router-link>
                </li>
                <li>
                  <router-link to="#" class="text-gray  mb-2"
                    >Helpdesk</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="footer-widget">
              <h5
                class="fw-semi-bold text-dark-400 widget-title text-white mb-3"
              >
                Exchange Pair
              </h5>
              <div class="row">
                <div class="d-flex justify-content-sm-between">
                  <ul class="list-unstyled widget-item">
                    <li>
                      <router-link to="#" class="text-gray  mb-2"
                        >ETH to BTC</router-link
                      >
                    </li>
                    <li>
                      <router-link to="#" class="text-gray  mb-2"
                        >BTC to ETH</router-link
                      >
                    </li>
                    <li>
                      <router-link to="#" class="text-gray  mb-2"
                        >LTC to ETH</router-link
                      >
                    </li>
                    <li>
                      <a href="#!" class="text-gray  mb-2">USDT to BTC</a>
                    </li>
                  </ul>
                  <ul class="list-unstyled widget-item ms-5 ms-sm-0">
                    <li>
                      <a href="#!" class="text-gray  mb-2">BTC to USDT</a>
                    </li>
                    <li>
                      <router-link to="#" class="text-gray  mb-2"
                        >LTC to BTC</router-link
                      >
                    </li>
                    <li>
                      <router-link to="#" class="text-gray  mb-2"
                        >XMR to BTC</router-link
                      >
                    </li>
                    <li>
                      <router-link to="#" class="text-gray  mb-2"
                        >ETC to DASH</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="copyrights bg-dark-blue py-4">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-9 col-md-8">
            <p class=" mb-0 text-center text-sm-start text-gray fs-6">
              Copyright ©
              {{ new Date().getFullYear() }}
              <a
                href="https://themeforest.net/user/quixlab"
                target="_blank"
                class="copyright-links fw-bold text-gray ms-1 text-hover-primary"
              >
                Quixlab</a
              >
              All Rights Reserved.
            </p>
          </div>

          <div class="col-12 col-sm-3 col-md-4">
            <ul
              class="social list-group list-group-horizontal justify-content-center justify-content-sm-end mt-3 mt-sm-0"
            >
              <li class="list-group-item border-0 bg-transparent p-0">
                <a
                  href="https://www.facebook.com/qxlab"
                  target="_blank"
                  class="text-gray px-2"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li class="list-group-item border-0 bg-transparent p-0">
                <a
                  href="https://twitter.com/quixlab"
                  target="_blank"
                  class="text-gray px-2"
                  ><i class="fab fa-twitter"></i
                ></a>
              </li>
              <li class="list-group-item border-0 bg-transparent p-0">
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  class="text-gray px-2"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </li>
              <li class="list-group-item border-0 bg-transparent p-0">
                <a
                  href="https://www.youtube.com/channel/UChN1goECpg64jlUxSzySiNg?view_as=subscriber"
                  target="_blank"
                  class="text-gray px-2"
                  ><i class="fab fa-youtube"></i
                ></a>
              </li>
            </ul>
            <!-- social-icons end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingFooter",
};
</script>
