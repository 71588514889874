<template>
  <light-layout :active="4">
    <section class="blog section-padding">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-8">
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4">
              <div class="col">
                <div class="card h-100 shadow-2 border-0 bg-dark-2">
                  <img
                    class="img-fluid card-img-top rounded-0"
                    src="../../assets/images/blog/1.jpg"
                    loading="lazy"
                    alt="…"
                  />
                  <div class="card-body  p-4">
                    <router-link to="blog-single">
                      <h5
                        class="fw-semi-bold text-dark-400 card-title fs-5 mb-3"
                      >
                        How to securely store your HD wallet seeds?
                      </h5>
                    </router-link>
                    <p class="text-gray card-text">
                      Cras chinwag brown bread Eaton cracking goal so I said a
                      load of old tosh baking cakes.!
                    </p>
                  </div>
                  <div
                    class="card-footer bg-transparent border-0 pt-0 pb-4 px-4"
                  >
                    <ul class="d-flex justify-content-between">
                      <a href="#!" class=" text-gray"
                        ><img
                          src="../../assets/images/avatar/5.jpg"
                          loading="lazy"
                          alt="…"
                          class="rounded-circle me-2"
                        />
                        Admin</a
                      >
                      <a href="#!" class="text-gray fs-6"
                        ><i class="la la-calendar"></i> 31 July, 2019</a
                      >
                    </ul>
                  </div>
                </div>
              </div>
              <!-- card end -->
              <div class="col">
                <div class="card h-100 shadow-2 border-0 bg-dark-2">
                  <img
                    class="img-fluid card-img-top rounded-0"
                    src="../../assets/images/blog/2.jpg"
                    loading="lazy"
                    alt="…"
                  />
                  <div class="card-body  p-4">
                    <router-link to="blog-single">
                      <h5
                        class="fw-semi-bold text-dark-400 card-title fs-5 mb-3"
                      >
                        How to securely store your HD wallet seeds?
                      </h5>
                    </router-link>
                    <p class="text-gray card-text">
                      Cras chinwag brown bread Eaton cracking goal so I said a
                      load of old tosh baking cakes.!
                    </p>
                  </div>
                  <div
                    class="card-footer bg-transparent border-0 pt-0 pb-4 px-4"
                  >
                    <ul class="d-flex justify-content-between">
                      <a href="#!" class=" text-gray"
                        ><img
                          src="../../assets/images/avatar/6.jpg"
                          loading="lazy"
                          alt="…"
                          class="rounded-circle me-2"
                        />
                        Admin</a
                      >
                      <a href="#!" class="text-gray fs-6"
                        ><i class="la la-calendar"></i> 31 July, 2019</a
                      >
                    </ul>
                  </div>
                </div>
              </div>
              <!-- card end -->
              <div class="col">
                <div class="card h-100 shadow-2 border-0 bg-dark-2">
                  <img
                    class="img-fluid card-img-top rounded-0"
                    src="../../assets/images/blog/3.jpg"
                    loading="lazy"
                    alt="…"
                  />
                  <div class="card-body  p-4">
                    <router-link to="blog-single">
                      <h5
                        class="fw-semi-bold text-dark-400 card-title fs-5 mb-3"
                      >
                        How to securely store your HD wallet seeds?
                      </h5>
                    </router-link>
                    <p class="text-gray card-text">
                      Cras chinwag brown bread Eaton cracking goal so I said a
                      load of old tosh baking cakes.!
                    </p>
                  </div>
                  <div
                    class="card-footer bg-transparent border-0 pt-0 pb-4 px-4"
                  >
                    <ul class="d-flex justify-content-between">
                      <a href="#!" class=" text-gray"
                        ><img
                          src="../../assets/images/avatar/7.jpg"
                          loading="lazy"
                          alt="…"
                          class="rounded-circle me-2"
                        />
                        Admin</a
                      >
                      <a href="#!" class="text-gray fs-6"
                        ><i class="la la-calendar"></i> 31 July, 2019</a
                      >
                    </ul>
                  </div>
                </div>
              </div>
              <!-- card end -->
              <div class="col">
                <div class="card h-100 shadow-2 border-0 bg-dark-2">
                  <img
                    class="img-fluid card-img-top rounded-0"
                    src="../../assets/images/blog/3.jpg"
                    loading="lazy"
                    alt="…"
                  />
                  <div class="card-body  p-4">
                    <router-link to="blog-single">
                      <h5
                        class="fw-semi-bold text-dark-400 card-title fs-5 mb-3"
                      >
                        How to securely store your HD wallet seeds?
                      </h5>
                    </router-link>
                    <p class="text-gray card-text">
                      Cras chinwag brown bread Eaton cracking goal so I said a
                      load of old tosh baking cakes.!
                    </p>
                  </div>
                  <div
                    class="card-footer bg-transparent border-0 pt-0 pb-4 px-4"
                  >
                    <ul class="d-flex justify-content-between">
                      <a href="#!" class=" text-gray"
                        ><img
                          src="../../assets/images/avatar/7.jpg"
                          loading="lazy"
                          alt="…"
                          class="rounded-circle me-2"
                        />
                        Admin</a
                      >
                      <a href="#!" class="text-gray fs-6"
                        ><i class="la la-calendar"></i> 31 July, 2019</a
                      >
                    </ul>
                  </div>
                </div>
              </div>
              <!-- card end -->
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="blog-sidebar ms-lg-4">
              <div class="widget mb-5 position-relative">
                <form action="#" class="form search-form">
                  <input
                    type="text"
                    class="form-control bg-transparent"
                    placeholder="Subscribe Now"
                  />
                  <button type="button" class="bg-transparent border-0">
                    <span><i class="la la-search"></i></span>
                  </button>
                </form>
              </div>
              <!-- widget end -->
              <div class="widget mb-5">
                <h4
                  class="text-dark-400 widget-title border-bottom fw-semi-bold pb-2 mb-4"
                >
                  Recent Post
                </h4>
                <ul class="list-unstyled">
                  <li class="media mb-4 d-flex align-items-center">
                    <img
                      src="../../assets/images/blog/post_1.jpg"
                      class="me-3 rounded"
                      loading="lazy"
                      alt="…"
                    />
                    <div class="media-body">
                      <h5 class="fw-semi-bold mb-1">
                        <a href="#!" class="text-dark-400"
                          >List-based media object</a
                        >
                      </h5>
                      <div class="meta-info">
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-user"></i> Admin</a
                        >
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-calendar"></i> 31 July, 2019</a
                        >
                      </div>
                    </div>
                  </li>
                  <li class="media mb-4 d-flex align-items-center">
                    <img
                      src="../../assets/images/blog/post_2.jpg"
                      class="me-3 rounded"
                      loading="lazy"
                      alt="…"
                    />
                    <div class="media-body">
                      <h5 class="fw-semi-bold mb-1">
                        <a href="#!" class="text-dark-400"
                          >List-based media object</a
                        >
                      </h5>
                      <div class="meta-info">
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-user"></i> Admin</a
                        >
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-calendar"></i> 31 July, 2019</a
                        >
                      </div>
                    </div>
                  </li>
                  <li class="media mb-4 d-flex align-items-center">
                    <img
                      src="../../assets/images/blog/post_3.jpg"
                      class="me-3 rounded"
                      loading="lazy"
                      alt="…"
                    />
                    <div class="media-body">
                      <h5 class="fw-semi-bold mb-1">
                        <a href="#!" class="text-dark-400"
                          >List-based media object</a
                        >
                      </h5>
                      <div class="meta-info">
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-user"></i> Admin</a
                        >
                        <a href="#!" class="text-gray me-1"
                          ><i class="la la-calendar"></i> 31 July, 2019</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- widget end -->
              <div class="widget mb-5">
                <h4
                  class="text-dark-400 widget-title border-bottom fw-semi-bold pb-2 mb-4"
                >
                  Categories
                </h4>
                <ul class="list-group">
                  <li
                    class="list-group-item bg-transparent d-flex justify-content-between align-items-center border-0 ps-0"
                  >
                    <a href="#!" class="text-gray">Cras justo odio</a>
                    <span class="badge bg-primary">14</span>
                  </li>
                  <li
                    class="list-group-item bg-transparent d-flex justify-content-between align-items-center border-0 ps-0"
                  >
                    <a href="#!" class="text-gray">Dapibus ac facilisis in</a>
                    <span class="badge bg-primary">2</span>
                  </li>
                  <li
                    class="list-group-item bg-transparent d-flex justify-content-between align-items-center border-0 ps-0"
                  >
                    <a href="#!" class="text-gray">Morbi leo risus</a>
                    <span class="badge bg-primary">1</span>
                  </li>
                </ul>
              </div>
              <!-- widget end -->
              <div class="widget widget-tag mb-5">
                <h4
                  class="text-dark-400 widget-title border-bottom fw-semi-bold pb-2 mb-4"
                >
                  Tags
                </h4>
                <ul class="list-inline tag-group">
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Tradix</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Song</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Title</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Good</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#!"
                      class="text-gray border rounded mb-3 fs-6 p-1 px-3"
                      >Dashboard</a
                    >
                  </li>
                </ul>
              </div>
              <!-- widget end -->
            </div>
          </div>
          <!-- blog-sidebar end -->
        </div>
      </div>
    </section>
  </light-layout>
</template>

<script>
// import DarkLayout from "../../components/Landing/Dark/DarkLayout.vue";
import LightLayout from "../../components/Landing/Light/LightLayout.vue";
export default {
  components: { LightLayout },
};
</script>
